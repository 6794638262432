import React, { useContext, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { Button, CardBody, Form, FormGroup, Input, Label } from 'reactstrap'
import Code, { ResponseStatus } from '../Helper/API'
import ResponseBeyable, { CodesType } from './ResponseBeyable'
import CsvDownloadButton from 'react-json-to-csv'
import { ApiConfigContext } from '../provider/environnement'

function GenerateCode() {
    const [numberOfCode, setNumberOfCode] = useState<undefined | number>(
        undefined
    )
    const [codeReference, setCodeReference] = useState('')
    const [codeDate, setCodeDate] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [codesGenerated, setCodesGenerated] = useState<CodesType[]>([])
    const [responseMessage, setResponseMessage] = useState<ResponseStatus | ''>(
        ''
    )
    const apiConfig = useContext(ApiConfigContext)
    const submitForm = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        if (!(numberOfCode && codeReference && codeDate)) {
            setResponseMessage('empty')
            return
        }
        setIsLoading(true)

        const code = new Code(apiConfig)

        code.addCode(
            Number(numberOfCode),
            codeReference,
            `${codeDate?.replace(/[-/]/g, '')}T0003Z`
        )
            .then((res) => {
                setResponseMessage(
                    res?.data?.status === 409
                        ? 'tomuch'
                        : res?.data?.back?.status || res?.status
                )
                setCodesGenerated(
                    res?.data?.back.map((code: CodesType) => {
                        return {
                            ...code,
                            ExpirationDateUtc: `${codeDate?.replace(
                                /[-/]/g,
                                ''
                            )}T0003Z`,
                        }
                    })
                )
            })
            .catch((err) => {
                console.log(err)
                setResponseMessage('catch-error')
            })
            .then(() => setIsLoading(false))
    }

    return (
        <CardBody>
            <Form inline onSubmit={submitForm}>
                <h3 className="mb-2 me-sm-2">Générer des codes promos</h3>
                {isLoading ? (
                    <TailSpin color="#f83062" ariaLabel="loading-indicator" />
                ) : (
                    <>
                        <FormGroup className="mb-2 me-sm-2">
                            <Label className="me-sm-2" for="code-nbr">
                                Nombre de code à créer
                            </Label>
                            <Input
                                id="code-nbr"
                                name="code-nbr"
                                placeholder="200"
                                type="number"
                                value={numberOfCode}
                                onChange={(e) =>
                                    setNumberOfCode(
                                        Number(e.currentTarget.value) > 0
                                            ? Number(e.currentTarget.value)
                                            : 0
                                    )
                                }
                            />
                        </FormGroup>

                        <FormGroup className="mb-2 me-sm-2">
                            <Label className="me-sm-2" for="code-ref">
                                Code de référence
                            </Label>
                            <Input
                                id="code-ref"
                                name="code-ref"
                                placeholder="LETSG00"
                                type="text"
                                value={codeReference}
                                onChange={(e) =>
                                    setCodeReference(e.currentTarget.value)
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Date d'expiration</Label>
                            <Input
                                type="date"
                                value={codeDate?.toString()}
                                className={codeDate?.toString() ? 'active' : ''}
                                onChange={(e) =>
                                    setCodeDate(e.currentTarget.value)
                                }
                            />
                        </FormGroup>

                        {/* <FormGroup check>
                            <Input
                                id="csv"
                                name="check"
                                type="checkbox"
                                defaultChecked
                            />
                            <Label
                                check
                                for="csv"
                                style={{ cursor: 'pointer' }}
                            >
                                Avoir un CSV
                            </Label>
                        </FormGroup> */}

                        <Button
                            color="primary"
                            onClick={
                                numberOfCode && codeReference && codeDate
                                    ? submitForm
                                    : () => setResponseMessage('empty')
                            }
                        >
                            Générer
                        </Button>

                        {codesGenerated.length > 0 && (
                            <CsvDownloadButton
                                className={'btn btn-secondary ms-2'}
                                data={codesGenerated}
                                filename={`${codeReference.toLowerCase()}_${codeDate}.csv`}
                            >
                                Télécharger en csv
                            </CsvDownloadButton>
                        )}
                    </>
                )}

                {!isLoading && responseMessage ? (
                    <ResponseBeyable
                        message={responseMessage}
                        codes={codesGenerated}
                    />
                ) : null}
            </Form>
        </CardBody>
    )
}

export default GenerateCode
