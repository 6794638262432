import React, { useContext, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Code, { ResponseStatus } from '../Helper/API'
import ResponseBeyable from './ResponseBeyable'
import { ApiConfigContext } from '../provider/environnement'

function DeleteCode() {
    const [codeReference, setCodeReference] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState<ResponseStatus | ''>(
        ''
    )
    const apiConfig = useContext(ApiConfigContext)

    const submitForm = (e: { preventDefault: () => void }) => {
        e.preventDefault()

        if (!codeReference) {
            return setResponseMessage('empty')
        }

        setIsLoading(true)
        setResponseMessage('')

        const code = new Code(apiConfig)

        code.deleteCode(codeReference)
            .then((res) =>
                setResponseMessage(
                    res?.status === 200
                        ? 'delete'
                        : res?.status === 400
                        ? 'not-found'
                        : 'Account is invalid'
                )
            )
            .catch((err) => {
                console.log(err)
                setResponseMessage('catch-error')
            })
            .then(() => setIsLoading(false))
    }

    return (
        <CardBody>
            <Form inline onSubmit={submitForm}>
                <h3 className="mb-2 me-sm-2">Supprimer un code promo</h3>
                {isLoading ? (
                    <TailSpin color="#f83062" ariaLabel="loading-indicator" />
                ) : (
                    <>
                        <FormGroup className="mb-2 me-sm-2">
                            <Label className="me-sm-2" for="code-ref">
                                Code de référence
                            </Label>
                            <Input
                                id="code-ref"
                                name="code-ref"
                                placeholder="LETSG00"
                                type="text"
                                value={codeReference}
                                onChange={(e) =>
                                    setCodeReference(e.currentTarget.value)
                                }
                            />
                        </FormGroup>

                        <Button
                            color="primary"
                            onClick={
                                codeReference
                                    ? submitForm
                                    : () => setResponseMessage('empty')
                            }
                        >
                            Supprimer
                        </Button>
                    </>
                )}
            </Form>

            {!isLoading && responseMessage ? (
                <ResponseBeyable message={responseMessage} />
            ) : null}
        </CardBody>
    )
}

export default DeleteCode
