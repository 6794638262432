import { createContext } from 'react'

export type IEnvironnement = 'PROD' | 'REC'
export type ILocal = 'FR' | 'BE'

export type IApiConfig = {
    environnement: IEnvironnement
    local: ILocal
}

export const ApiConfigContext = createContext<IApiConfig>({
    environnement: 'PROD',
    local: 'FR',
})
