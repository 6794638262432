import axios, { AxiosInstance } from 'axios'
import { IApiConfig } from '../provider/environnement'

class Code {
    api_url: string | undefined
    client!: AxiosInstance
    local: 'FR' | 'BE'
    environnement: 'PROD' | 'REC'

    constructor(apiConfig: IApiConfig) {
        this.local = apiConfig.local
        this.environnement = apiConfig.environnement
    }

    init = () => {
        this.client = axios.create({
            // baseURL: 'http://localhost:5004',
            timeout: 8000,
        })

        return this.client
    }

    deleteCode = (code: string) => {
        return this.init().post('/disabledCodeUnique', {
            data: {
                code: code,
                local: this.local,
                environnement: this.environnement,
            },
        })
    }

    addCode = (
        number: number,
        code: string,
        dateExpiration: string,
        csv = false
    ) => {
        return this.init().post('/generateCodeUnique', {
            data: {
                number: number,
                code: code,
                date: dateExpiration,
                local: this.local,
                environnement: this.environnement,
                downloadcsv: csv,
            },
        })
    }

    testCode = (code: string) => {
        return this.init().post('/checkCodeUnique', {
            data: {
                code: code,
                local: this.local,
                environnement: this.environnement,
            },
        })
    }
}

export type ResponseStatus =
    | 'OK'
    | 'NOK-ERROR-DISCOUNTNOTEXISTS'
    | 'NOK-ERROR-EXPIREDDATE'
    | 'Account is invalid'
    | 'NOK-ERROR-ALREADYUSED'
    | 'delete'
    | 'tomuch'
    | '0'
    | 'catch-error'
    | 'not-found'
    | 'empty'
    | 404

export function translateResponse(status: ResponseStatus): string {
    switch (status) {
        case 'OK':
            return 'Le code est valide'
        case 'NOK-ERROR-ALREADYUSED':
            return 'Le code a déjà été utilisé'
        case 'NOK-ERROR-DISCOUNTNOTEXISTS':
            return "Le code n'existe pas"
        case 'NOK-ERROR-EXPIREDDATE':
            return 'Le code a expiré'
        case 'delete':
            return 'Le code a bien été burn'
        case 'tomuch':
            return 'Trop de codes ont été réservés, il faut contacter le support de BEYABLE'
        case '0':
            return "Problème de droit d'accès à l'API"
        case 'not-found':
        case 404:
            return 'Api not found'
        case 'empty':
            return 'Tous les champs ne sont pas valides'
        default:
            return "Il y a eu un soucis lié à l'appel vers l'API"
    }
}
export default Code
