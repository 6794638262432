import React from 'react'
import { Button, CardBody } from 'reactstrap'

interface Props {
    generateCode?: React.MouseEventHandler<HTMLButtonElement> | undefined
    checkCode?: React.MouseEventHandler<HTMLButtonElement> | undefined
    deleteCode?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

function Switch({ generateCode, checkCode, deleteCode }: Props) {
    return (
        <CardBody>
            <Button onClick={generateCode} className="w-100 mt-2 mb-2">
                Générer des codes promos
            </Button>
            <Button onClick={checkCode} className="w-100 mt-2 mb-2">
                Vérifier un code promo
            </Button>
            <Button onClick={deleteCode} className="w-100 mt-2 mb-2">
                Supprimer un code promo
            </Button>
        </CardBody>
    )
}

export default Switch
