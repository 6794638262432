import classnames from 'classnames'
import { FormGroup } from 'reactstrap'
import { IEnvironnement } from '../provider/environnement'

interface Props {
    environnement: IEnvironnement
    changeEnvironnement(environnement: IEnvironnement): void
}

function Environnement({ environnement, changeEnvironnement }: Props) {
    return (
        <FormGroup className="toggle-wrapper mb-0">
            <div
                onClick={() => changeEnvironnement('PROD')}
                className={classnames('toggle-button', {
                    active: environnement === 'PROD',
                })}
            >
                PRODUCTION
            </div>

            <div
                onClick={() => changeEnvironnement('REC')}
                className={classnames('toggle-button', {
                    active: environnement === 'REC',
                })}
            >
                RECETTE
            </div>
        </FormGroup>
    )
}

export default Environnement
