import classnames from 'classnames'
import { FormGroup } from 'reactstrap'
import { ILocal } from '../provider/environnement'

interface Props {
    local: ILocal
    changeLocal(local: ILocal): void
}

function Local({ local, changeLocal }: Props) {
    return (
        <FormGroup className="toggle-wrapper">
            <div
                onClick={() => changeLocal('FR')}
                className={classnames('toggle-button', {
                    active: local === 'FR',
                })}
            >
                FR
            </div>

            <div
                onClick={() => changeLocal('BE')}
                className={classnames('toggle-button', {
                    active: local === 'BE',
                })}
            >
                BE
            </div>
        </FormGroup>
    )
}

export default Local
