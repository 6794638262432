import React from 'react'
import { Row, Col, Table } from 'reactstrap'
import { ResponseStatus, translateResponse } from '../Helper/API'

interface Props {
    message: ResponseStatus
    test?: CheckCodeSuccessResponse
    codes?: CodesType[]
}

export interface CodesType {
    Code: string
    CampaignKey: string
    CreationDateUtc: string // date:yyyy-MM-dd HH:mm
    RequestId: string
    ExpirationDateUtc: string
}

export interface CheckCodeSuccessResponse {
    OriginalCode: string
    VisitorCode: string
    DiscountValue?: string
}

export default function ResponseBeyable({ message, test, codes }: Props) {
    if (message === '0') {
        return <p>Erreur de droit d'accès à l'api</p>
    }
    return (
        <Row>
            <Col sm={12} className="mt-4">
                {codes && codes.length > 0 ? (
                    <>
                        <h3 className="mb-2 me-sm-2">Liste de codes :</h3>
                        <DisplayCodes codes={codes} />
                    </>
                ) : (
                    <>
                        {message != 'empty' && (
                            <h3 className="mb-2 me-sm-2">
                                Réponse de Beyable :
                            </h3>
                        )}
                        <p>{translateResponse(message)}</p>

                        {message === 'OK' && test && (
                            <ul>
                                {test?.OriginalCode && (
                                    <li>
                                        Code d'origine :{' '}
                                        <u>{test?.OriginalCode}</u>
                                    </li>
                                )}
                                {test?.VisitorCode && (
                                    <li>
                                        Code généré : <u>{test?.VisitorCode}</u>
                                    </li>
                                )}
                                {test?.DiscountValue && (
                                    <li>
                                        Valeur de la promotion :{' '}
                                        <u>{test?.DiscountValue}</u>
                                    </li>
                                )}
                            </ul>
                        )}
                    </>
                )}
            </Col>
        </Row>
    )
}

interface DisplayCodesProps {
    codes: CodesType[]
}

function DisplayCodes({ codes }: DisplayCodesProps) {
    return (
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Campagn Key</th>
                    <th>Expiration date</th>
                </tr>
            </thead>

            <tbody>
                {codes.map(({ Code, CampaignKey, ExpirationDateUtc }, i) => {
                    return (
                        <tr key={`${Code}-${i}`}>
                            <td>{i + 1}</td>
                            <td>{Code}</td>
                            <td>{CampaignKey}</td>
                            <td>{ExpirationDateUtc}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
