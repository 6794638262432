import { useState } from 'react'
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Row,
} from 'reactstrap'
import DeleteCode from './Component/DeleteCode'
import GenerateCode from './Component/GenerateCode'
import Local from './Component/Local'
import Switch from './Component/Switch'
import TestCode from './Component/TestCode'
import {
    ApiConfigContext,
    IApiConfig,
    IEnvironnement,
    ILocal,
} from './provider/environnement'
import Environnement from './Component/Environnement'

function App() {
    const [formStep, setFormStep] = useState('')
    const [apiConfig, setApiConfig] = useState<IApiConfig>({
        environnement: 'PROD',
        local: 'FR',
    })

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={12} md={6}>
                    <Card className="box">
                        <CardHeader style={{ margin: 0, position: 'relative' }}>
                            <h5 className="text-center">Code de réduction</h5>
                            {formStep !== '' && (
                                <Button
                                    color="primary"
                                    style={{
                                        position: 'absolute',
                                        top: '1em',
                                        right: '1em',
                                    }}
                                    onClick={() => setFormStep('')}
                                >
                                    Retour
                                </Button>
                            )}
                        </CardHeader>
                        {!formStep && (
                            <Switch
                                generateCode={() => setFormStep('generate')}
                                checkCode={() => setFormStep('check')}
                                deleteCode={() => setFormStep('delete')}
                            />
                        )}

                        <ApiConfigContext.Provider value={apiConfig}>
                            <>
                                {
                                    {
                                        generate: <GenerateCode />,
                                        check: <TestCode />,
                                        delete: <DeleteCode />,
                                    }[formStep]
                                }
                            </>
                        </ApiConfigContext.Provider>

                        <CardFooter>
                            <Local
                                local={apiConfig.local}
                                changeLocal={(local: ILocal) => {
                                    setApiConfig((prev) => ({
                                        ...prev,
                                        local,
                                    }))
                                }}
                            />
                            <Environnement
                                environnement={apiConfig.environnement}
                                changeEnvironnement={(
                                    environnement: IEnvironnement
                                ) => {
                                    setApiConfig((prev) => ({
                                        ...prev,
                                        environnement,
                                    }))
                                }}
                            />
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default App
