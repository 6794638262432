import { useContext, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Code, { ResponseStatus } from '../Helper/API'
import ResponseBeyable, { CheckCodeSuccessResponse } from './ResponseBeyable'
import { ApiConfigContext } from '../provider/environnement'

function TestCode() {
    const [codeTest, setCodeTest] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState<ResponseStatus | ''>(
        ''
    )
    const [reponseFromCodeText, setResponseFromCodeText] = useState<
        CheckCodeSuccessResponse | undefined
    >(undefined)
    const apiConfig = useContext(ApiConfigContext)

    const submitForm = (e: { preventDefault: () => void }) => {
        e.preventDefault()

        if (!codeTest) {
            return setResponseMessage('empty')
        }
        setIsLoading(true)
        setResponseMessage('')

        const code = new Code(apiConfig)

        code.testCode(codeTest)
            .then((res) => {
                setResponseMessage(res?.data?.back?.status || res?.status)
                res?.data?.back?.status === 'OK' &&
                    setResponseFromCodeText({
                        OriginalCode: `${res?.data?.back?.result?.originalCode}`,
                        VisitorCode: `${res?.data?.back?.result?.visitorCode}`,
                    })
            })
            .catch((err) => {
                console.log(err)
                setResponseMessage('catch-error')
            })
            .then(() => setIsLoading(false))
    }

    return (
        <CardBody>
            <Form inline onSubmit={submitForm}>
                <h3 className="mb-2 me-sm-2">
                    Vérifier la validité d'un code promo
                </h3>

                {isLoading ? (
                    <TailSpin color="#f83062" ariaLabel="loading-indicator" />
                ) : (
                    <>
                        <FormGroup className="mb-2 me-sm-2">
                            <Label className="me-sm-2" for="code-ref">
                                Code à tester
                            </Label>
                            <Input
                                id="code-test"
                                name="code-test"
                                placeholder="LETSG00"
                                type="text"
                                value={codeTest}
                                onChange={(e) =>
                                    setCodeTest(e.currentTarget.value)
                                }
                            />
                        </FormGroup>
                        <Button
                            color="primary"
                            onClick={
                                codeTest
                                    ? submitForm
                                    : () => setResponseMessage('empty')
                            }
                        >
                            Tester
                        </Button>
                    </>
                )}
            </Form>

            {!isLoading && responseMessage ? (
                <ResponseBeyable
                    message={responseMessage}
                    test={reponseFromCodeText}
                />
            ) : null}
        </CardBody>
    )
}

export default TestCode
